<template>
    <b-row class="bg-white mb-5">
        <b-col md="6" offset-md="3">
            <div class="card">
                <div class="card-header">{{ titleStr }}</div>

                <div v-if="progress" class="progress">
                    <div
                            class="progress-bar progress-bar-light progress-bar-striped"
                            role="progressbar"
                            :aria-valuenow="progress"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            :style="{ width: progress + '%' }"
                    >
                        {{ progress }}%
                    </div>
                </div>

                <b-input-group>
                    <b-form-file
                        :state="Boolean(selectedFiles)"
                        :accept="acceptType"
                        v-model="selectedFiles"
                        multiple
                        ref="fileForm"
                        placeholder="Choose file(s)..."
                        drop-placeholder="Drop file(s) here...">
                    </b-form-file>
                    <b-input-group-append>
                        <button class="btn btn-success" :disabled="!selectedFiles" @click="upload">
                            Upload
                        </button>
                    </b-input-group-append>
                </b-input-group>

                <div v-if="selectedFiles" class="card">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item" v-for="(item, index) in selectedFiles" :key="index">
                            {{ item.name }}
                            <b-icon class="float-right" icon="trash" @click="removeItem(index, $event)"></b-icon>
                        </li>
                    </ul>
                </div>

                <div v-show="message" class="alert alert-light" role="alert">{{ message }}</div>

                <ul class="list-group list-group-flush">
                    <li class="list-group-item" v-for="(item, index) in fileInfos" :key="index">
                        <b-row>
                            <b-col cols="10" md="6">
                                <a target="_blank" :href="item.path">
                                    <MediaItem :key="item.id" :path="item.path" :thumbnail="item.thumbnail" :type="item.type"/>
                                </a>
                            </b-col>
                            <b-col>
                                <b-icon class="float-right" @click="onDelete(item.id, $event)" icon="trash" style="cursor: pointer;"></b-icon>
                            </b-col>
                        </b-row>
                    </li>
                </ul>
            </div>
        </b-col>
    </b-row>
</template>

<script>
    import UploadService from "../UploadService";
    import MediaItem from "./MediaItem";
    import axios from "axios";

    export default {
        name: 'UploadFiles',
        components: {
            MediaItem
        },
        /*
          Defines the data used by the component
        */
        data(){
            return {
                selectedFiles: [],
                progress: 0,
                message: "",
                fileInfos: []
            }
        },
        props: {
            falconId: {
                type: Number,
                default: null
            },
            category: {
                type: Number,
                default: 1
            },
            titleStr: {
                type: String,
                default: 'Portfolio'
            },
            acceptType: {
                type: String,
                default: "image/*, video/*"
            }
        },
        computed: {
            fid() {
                return this.falconId || this.$route.params.id
            }
        },
        methods: {
            fetchData() {
                axios.get("/api/v1/media/"+this.category+"/"+this.fid).then(response => {
                    this.fileInfos = response.data;
                });
            },
            selectFile() {
                this.message = ''
                this.selectedFiles = this.$refs.fileForm.files
            },
            upload() {
                this.progress = 0;

                UploadService.upload(this.fid, this.category, this.selectedFiles, event => {
                    this.progress = Math.round((100 * event.loaded) / event.total);
                })
                .then(response => {
                    this.message = response.data.message
                    this.progress = 0
                    this.fetchData()
                })
                .catch(() => {
                    this.progress = 0;
                    this.message = "Could not upload the file(s)!";
                });

                this.selectedFiles = [];
                this.progress = 0;
            },
            onDelete(id, evt) {
                evt.preventDefault()
                this.$confirm(
                    {
                        message: `Are you sure you want to delete this image?`,
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        callback: confirm => {
                            if (confirm) {
                                this.$http.delete('/api/v1/media/'+id)
                                    .then(response => {
                                        this.msg = response.data;
                                        this.fetchData()
                                    })
                                    .catch(error => console.log(error))
                            }
                        }
                    }
                )
            },
            removeItem(id, evt) {
                evt.preventDefault()
                this.selectedFiles.splice(id,1)
            }
        },
        mounted() {
            this.fetchData()
            this.$watch(
                () => {
                  return this.$refs.fileForm.files
                },
                (val) => {
                  console.log(val)
                  if(val.length !== 0) {
                    this.message = ''
                  }
                }
            )
        }
    }
</script>

<style scoped>

</style>