import axios from 'axios';

class UploadService {
    upload(id, cat, files, onUploadProgress) {
        let formData = new FormData();

        Array.from(files).forEach(file => formData.append(file.name,file));

        return axios.post("/api/v1/media/upload/"+cat+"/"+id, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress
        });
    }

    uploadHeaderImage(endpoint, formData, onUploadProgress) {
        return axios.post("/api/v1/media/"+endpoint, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress
        });
    }

    uploadAvatarImage(endpoint, formData, onUploadProgress) {
        return axios.post("/api/v1/media/"+endpoint, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress
        });
    }
}

export default new UploadService();