<template>
    <div>
        <IconMenu :falcon-id="detail.id"></IconMenu>
        <div v-if="loaded">
            <b-container class="bg-dark">
                <b-row>
                    <b-col md="4" class="item mb-2 pr-1 pl-1" v-for="(image, imageIndex) in sortedMedia" :key="imageIndex" @click="setIndex(imageIndex)">
                        <b-link :href="image.path" target="_blank">
                            <b-img-lazy class="image" :src="image.thumbnail" fluid-grow blank show></b-img-lazy>
                        </b-link>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>

<script>
    import IconMenu from "./IconMenu"

    export default {
        name: "Bio",
        components: {
            IconMenu
        },
        data() {
            return {
                loaded: false,
                detail: {
                    id: null,
                },
                media: [],
                index: null
            }
        },
        mounted() {
            this.loaded = true
            try {
                this.fetchData()
            } catch(e) {
                console.log(e)
            }
        },
        methods: {
            async fetchData () {
                await this.$http.get('/api/v1/details/'+this.$route.params.hash)
                    .then(response => {
                        this.detail = response.data
                        this.loaded = true
                    })
                    .catch(error => console.log(error))
                await this.$http.get('/api/v1/media/2/'+this.detail.id)
                    .then(response => {
                        this.media = response.data
                    })
                    .catch(error => console.log(error))
            },
        },
        computed: {
            sortedMedia: function() {
                return this._.orderBy(this.media, 'sort_number', 'desc')
            },
            mediaArray: function() {
                let tempArr
                tempArr = this.sortedMedia.map(item=> {
                        return {
                            src: item.path,
                            thumb: item.thumbnail
                        }
                    }
                )
                return tempArr
            }
        }
    }
</script>

<style scoped>

</style>