<template>
    <div class="bg-white">
        <h3 class="float-left">Falcons</h3>
        <router-link class="float-right mt-1 mr-1 mb-2" to="/admin/falcons/create"><b-button variant="success">Create</b-button></router-link>
        <b-table striped hover bordered responsive :items="falcons" :fields="fields">
            <template v-slot:cell(url)="data">
                <b-link target="_blank" :to='"/details/"+data.item.hash'>https://falcons.digital/details/{{ data.item.hash }}</b-link>
            </template>
            <template v-slot:cell(created_at)="data">
                <span v-b-tooltip.hover.bottom :title="data.item.created_at | formatDate | moment('from','now')">{{ data.item.created_at | formatDate('YYYY-MM-DD HH:mm') }}</span>
            </template>
            <template v-slot:cell(updated_at)="data">
                <span v-b-tooltip.hover.bottom :title="data.item.updated_at | formatDate | moment('from','now')">{{ data.item.updated_at | formatDate('YYYY-MM-DD HH:mm') }}</span>
            </template>
            <template v-slot:cell(edit)="data">
                <b-link :to='"/admin/falcons/"+data.item.id+"/edit"'><b-button variant="warning" size="sm"><b-icon icon="pencil"></b-icon></b-button></b-link>
                <b-link :to='"/admin/media/"+data.item.id'><b-button variant="light" size="sm"><b-icon icon="images"></b-icon></b-button></b-link>
                <b-link :to='"/admin/bio/"+data.item.id'><b-button variant="light" size="sm"><b-icon icon="file-text"></b-icon></b-button></b-link>
                <b-link :to='"/admin/health/"+data.item.id'><b-button variant="light" size="sm"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="-3 1 22 22" class="bi-grid b-icon bi" role="img" fill="currentColor">
                    <title>first-aid</title>
                    <path d="M11.5 16h-3c-0.276 0-0.5-0.224-0.5-0.5v-1.5h-1.5c-0.276 0-0.5-0.224-0.5-0.5v-3c0-0.276 0.224-0.5 0.5-0.5h1.5v-1.5c0-0.276 0.224-0.5 0.5-0.5h3c0.276 0 0.5 0.224 0.5 0.5v1.5h1.5c0.276 0 0.5 0.224 0.5 0.5v3c0 0.276-0.224 0.5-0.5 0.5h-1.5v1.5c0 0.276-0.224 0.5-0.5 0.5zM9 15h2v-1.5c0-0.276 0.224-0.5 0.5-0.5h1.5v-2h-1.5c-0.276 0-0.5-0.224-0.5-0.5v-1.5h-2v1.5c0 0.276-0.224 0.5-0.5 0.5h-1.5v2h1.5c0.276 0 0.5 0.224 0.5 0.5v1.5z"></path>
                    <path d="M18.5 5h-4.5v-1.5c0-0.827-0.673-1.5-1.5-1.5h-5c-0.827 0-1.5 0.673-1.5 1.5v1.5h-4.5c-0.827 0-1.5 0.673-1.5 1.5v11c0 0.827 0.673 1.5 1.5 1.5h17c0.827 0 1.5-0.673 1.5-1.5v-11c0-0.827-0.673-1.5-1.5-1.5zM7 3.5c0-0.276 0.224-0.5 0.5-0.5h5c0.276 0 0.5 0.224 0.5 0.5v1.5h-6v-1.5zM19 17.5c0 0.276-0.224 0.5-0.5 0.5h-17c-0.276 0-0.5-0.224-0.5-0.5v-11c0-0.276 0.224-0.5 0.5-0.5h17c0.276 0 0.5 0.224 0.5 0.5v11z"></path>
                </svg></b-button></b-link>
            </template>
        </b-table>
    </div>
</template>

<script>
    export default {
        name: "Falcons",
        data() {
            return {
                fields: [
                    {
                        key: 'id',
                        sortable: true
                    },
                    {
                        key: 'name',
                        sortable: true
                    },
                    {
                        key: 'ring',
                        sortable: true
                    },
                    {
                        key: 'uid',
                        sortable: true
                    },
                    {
                        key: 'url',
                        sortable: true
                    },
                    {
                        key: 'created_at',
                        sortable: true,
                        formatter: value => {
                            return this.$moment(String(value)).format('LLLL')
                        }
                    },
                    {
                        key: 'updated_at',
                        sortable: true
                    },
                    {
                        key: 'edit',
                        sortable: false
                    }],
                falcons: []
            }
        },
        created () {
            this.fetchData()
        },
        methods: {
            async fetchData() {
                await this.$http.get('/api/v1/falcons').then(response => this.falcons = response.data).catch(error => console.log(error))
            },
        }
    }
</script>

<style scoped>

</style>