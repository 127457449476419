<template>
    <div class="bg-dark">
        <b-row>
            <b-col md="6" offset-md="3">
                <b-card class="mt-5 bg-dark">
                    <b-form @submit="login">
                        <b-card-text v-if="!this.$store.getters.isLoggedIn">
                            <h2 class="text-light">Login</h2>
                            <p v-if="!this.$route.params.user" class="text-light">To login use the username and password of your <b>falcons.digital account</b>.</p>
                            <p v-if="this.$route.params.user" class="text-light">To login enter the password on your <b>falcons.digital owner card</b>.</p>
                            <b-form-group id="loginForm2" class="mt-2 form form-contact bg-dark">
                                <b-form-input v-if="!this.$route.params.user" v-model="loginUser" placeholder="Name" type="text" class="mb-1"></b-form-input>
                                <b-form-input v-model="loginPassword" placeholder="Password" type="password"></b-form-input>
                                <b-button class="mt-2" type="submit" variant="light" @click="login">Login</b-button>
                                <b-alert class="mt-2" show variant="danger" v-if="msg">{{ msg }}</b-alert>
                            </b-form-group>
                            <b-link href="mailto:info@falcons.digital" target="_blank" class="card-link text-light">Need help? Send us an email.</b-link>
                        </b-card-text>
                        <b-card-text v-if="this.$store.getters.isLoggedIn">
                            <h2 class="text-light">Logged in as</h2>
                            <h6 class="text-light">{{ this.$store.getters.getUser.name }}</h6>
                            <b-link class="card-link text-light" :to="{ name: 'logout' }">Logout</b-link>
                        </b-card-text>
                    </b-form>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import AuthService from '@/AuthService.js';

    export default {
        name: "Login",
        data() {
            return {
                loginUser: this.$route.params.user,
                loginPassword: '',
                msg: ''
            }
        },
        mounted() {
            if(this.$store.getters.isLoggedIn && this.$route.query.redirect) {
                this.$router.push({ path: this.$route.query.redirect })
            } else if(this.$store.getters.isLoggedIn && this.$route.params.user && this.$route.params.user === this.$store.getters.getUser.name){
                this.$router.push('/dashboard')
            } else if(this.$store.getters.isLoggedIn && this.$route.params.user && this.$route.params.user !== this.$store.getters.getUser.name) {
                this.$router.push('/logout/'+this.$route.params.user);
            } else if(this.$store.getters.isLoggedIn && typeof this.$route.params.user === 'undefined') {
                this.$router.push('/admin/falcons')
            }
        },
        methods: {
            async login(e) {
                e.preventDefault()
                try {
                    const credentials = {
                        username: this.loginUser,
                        password: this.loginPassword,
                        grant_type: "password",
                        client_id: 1,
                        client_secret: process.env.VUE_APP_SCHL,
                        scope: ""
                    };
                    const response = await AuthService.login(credentials);
                    const token = response.data.access_token;
                    const responseU = await AuthService.user(token);
                    const user = responseU.data
                    this.$store.dispatch('login', { token, user });
                    this.$matomo.push(['setUserId',user.name])
                    if(this.$route.query.redirect) {
                        this.$router.push({ path: this.$route.query.redirect })
                        return
                    }
                    if(this.$store.getters.isAdmin) {
                        this.$router.push('/admin/falcons');
                    } else {
                        this.$router.push('/dashboard');
                    }
                } catch (error) {
                    this.msg = error.response.data.message;
                }
            }
        },
        computed: {
            validation() {
                return this.loginUser.length > 4 && this.loginUser.length < 13
            }
        }
    }
</script>

<style scoped>

</style>