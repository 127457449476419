<template>
  <div id="#app">
    <vue-confirm-dialog></vue-confirm-dialog>
    <div id="contentwrapper">
      <transition name="slide-up-fade" appear>
        <Navbar v-show="hiddenLoader" />
      </transition>
      <transition name="slide-up-fade">
        <radial-progress-bar v-show="!hideLoader"
                             :diameter="loaderWidth"
                             :completed-steps="completedSteps"
                             :total-steps="totalSteps"
                             :animateSpeed="1000" :startColor="`#ffffff`" :stopColor="`#ffffff`" :innerStrokeColor="`#95999c`" :strokeWidth="3" :innerStrokeWidth="3" :timingFunc="`ease-in-out`" :fps="120">
          <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               viewBox="0 0 56.619359 67.160002" style="enable-background:new 0 0 56.619359 67.160002; width: auto; height: 100px;" xml:space="preserve">
                    <g id="g5" transform="translate(-273.23064,-376.65)">
                        <path class="st0" d="m 287.92,431.76 c -6.89,-31.2 21.62,-45.21 41.93,-55.11 -22.47,6.58 -58.86,26.14 -41.93,55.11 z" id="path7" style="clip-rule:evenodd;fill-rule:evenodd" />
                      <path class="st0" d="m 298.42,443.81 c -13.97,-28.74 11.58,-51.8 29.63,-62.57 -23.06,9.61 -52.84,38.34 -29.63,62.57 z" id="path9" style="clip-rule:evenodd;fill-rule:evenodd" />
                      <path class="st0" d="m 285.64,401.81 c -1.19,0.49 -2.95,-0.07 -5.27,-1.7 -2.55,-1.34 -4.46,-1.21 -5.74,0.4 -0.04,0.01 -0.07,0.02 -0.1,0.03 0.05,0.17 0.07,0.34 0.07,0.53 0,0.52 0.04,1.03 0.06,1.55 0.01,0.31 0.01,0.62 -0.01,0.93 0.04,0.11 0.09,0.23 0.12,0.34 3.13,1.9 4.71,6.26 4.74,13.1 l 1.98,1.27 c -0.61,-6.39 0.77,-11.87 4.15,-16.45 z" id="path11" style="clip-rule:evenodd;fill-rule:evenodd" />
                      <path d="m 275.07,400.02 c -0.84,0.41 -1.24,1.16 -1.37,2.09 -0.08,0.6 -0.2,1.18 -0.41,1.74 -0.06,0.17 -0.07,0.38 -0.05,0.57 0.05,0.55 0.35,1 0.63,1.44 0.17,0.26 0.46,0.37 0.8,0.26 -0.25,-0.58 0.06,-0.94 0.36,-1.37 0.17,0.06 0.36,0.09 0.52,0.19 0.44,0.27 0.86,0.56 1.3,0.84 0.28,0.18 0.42,0.46 0.52,0.77 l -2.3,-6.53 z" id="path13"/>
                    </g>
                    </svg>
          <h2 class="text-light" style="font-family: 'Eurostile';">Falcons.Digital</h2>
        </radial-progress-bar>
      </transition>
      <div id="click">
        <router-view></router-view>
      </div>
      <footer class="pl-5 pr-5 pb-3">
        <b-link class="text-white" v-if="false && this.$store.getters.isAdmin" to="/admin/falcons">Falcons</b-link>&nbsp;
        <b-link class="text-white" to="/impressum">Imprint</b-link>&nbsp;
        <b-link class="text-white" to="/impressum#datenschutz">Privacy</b-link>&nbsp;
        <cookie-law theme="dark-lime">
        </cookie-law>
      </footer>
    </div>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue'
import RadialProgressBar from 'vue-radial-progress'
import CookieLaw from 'vue-cookie-law'

export default {
  name: 'App',
  components: {
    Navbar, RadialProgressBar, CookieLaw
  },
  data () {
    return {
      completedSteps: 0,
      totalSteps: 100,
      hideLoader: false,
      hiddenLoader: false,
      startColor: "#ffffff",
      stopColor: "#ffffff",
      windowWidth: window.innerWidth,
    }
  },
  mounted: function() {
    /*document.addEventListener("touchstart", event => {
      if(event.touches.length > 1) {
        event.preventDefault();
        event.stopPropagation(); // maybe useless
      }
    }, {passive: false})*/

    setTimeout(()=>{
      this.completedSteps = 100
      setTimeout(()=>{
        this.hideLoader = true
        setTimeout(()=>{
          this.hiddenLoader = true
        },500)
      },1000)
    },200)

    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
      console.log(this.windowWidth)
    })
  },
  /* eslint-disable no-unused-vars */
  created: function () {
    let localSet = window.navigator.userLanguage || window.navigator.language
    this.$moment.locale(localSet)
    let that = this
    this.$http.interceptors.response.use(undefined, function (err) {
      return new Promise(function (resolve, reject) {
        if (window.location.pathname !== "/login" && err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
          that.$store.dispatch('logout')
        }
        throw(err)
      });
    });
  },
  /* eslint-enable no-unused-vars */
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('home')
    }
  },
  computed: {
    isAdmin() {
      return this.$route.path.includes('/admin/')
    },
    loaderWidth() {
      return this.windowWidth * 0.4 < 350 ? 350 : this.windowWidth * 0.4
    }
  },
}
</script>

<style>
html, body {
  background-color: #2E2D2C !important;
  font-family: 'Raleway', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: auto!important;
  overflow-x: hidden!important;
}
.bg-dark {
  background-color: #2E2D2C !important;
}
h1, h2, h3 {
  font-family: 'Cinzel', serif;
}

.st0{fill-rule:evenodd;clip-rule:evenodd;}
#path7, #path9, #path11, #path13 {
  fill:white;
}


.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.slide-up-fade-enter-active {
  transition: all .3s ease;
}
.slide-up-fade-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-up-fade-enter, .slide-up-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-50px);
  opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transition: opacity .3s;
  opacity: 0;
}
.radial-progress-container {
  margin-top: 7em;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100em;
}

.gradient-effect {
  position:relative;
  display:inline-block;
}
.gradient-effect:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: radial-gradient(125% 50% at center, transparent 95%, #2E2D2C 100%);
}
.pagination .page-link{
  color: #2E2D2C !important;
}
.page-item.active .page-link {
  background-color: grey !important;
  border-color: grey !important;
}
</style>
