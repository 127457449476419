<template>
    <div class="bg-white">
        <b-col md="6" offset-md="3">
            <h2>Create Falcon</h2>
            <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                <b-form-group
                        id="input-group-1"
                        label="UID:"
                        label-for="input-1"
                >
                    <b-form-input
                            id="input-1"
                            v-model="falcon.uid"
                            v-mask="macMask"
                            masked="true"
                            required
                            placeholder="UID"
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                        id="input-group-3"
                        label="Name:"
                        label-for="input-3"
                >
                    <b-form-input
                            id="input-3"
                            v-model="falcon.name"
                            placeholder="Name"
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                        id="input-group-4"
                        label="Ring:"
                        label-for="input-4"
                >
                    <b-form-input
                            id="input-4"
                            v-model="falcon.ring"
                            placeholder="Offical ring number"
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                        id="input-group-5"
                        label="Züchtername:"
                        label-for="input-5"
                >
                    <b-form-input
                            id="input-5"
                            v-model="falcon.zuechter_name"
                            placeholder="Name des Züchters"
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                        id="input-group-6"
                        label="Züchter Website:"
                        label-for="input-6"
                >
                    <b-form-input
                            id="input-6"
                            v-model="falcon.zuechter_url"
                            placeholder="Website des Züchters"
                    ></b-form-input>
                </b-form-group>

                <b-button-group>
                    <b-button type="submit" variant="success">Create</b-button>
                    <b-button type="reset" variant="secondary">Reset</b-button>
                    <b-button type="button" v-on:click="$router.go(-1)" variant="light">Cancel</b-button>
                </b-button-group>
            </b-form>
            <b-alert show v-if="err" class="mt-3" variant="danger" >Error: {{ err }}</b-alert>
            <b-card v-show="msg" class="mt-3" :header="msg">
                <b-button type="button" v-on:click="$router.go(-1)" variant="light">Back</b-button>
                <!--<pre class="m-0">{{ msg }}</pre>-->
            </b-card>
        </b-col>
    </div>
</template>

<script>
    export default {
        name: "Create",
        data() {
            return {
                falcon: {
                    uid: '',
                },
                show: true,
                msg: '',
                err: '',
                macMask: {
                    mask: 'FF:FF:FF:FF:FF:FF:FF',
                    tokens: {
                        'F': {
                            pattern: /[0-9a-fA-F]/,
                            transform: function(v) {
                                return v.toLocaleUpperCase();
                            }
                        },
                        '#': {pattern: /\d/},
                        'X': {pattern: /[0-9a-zA-Z]/},
                        'S': {pattern: /[a-zA-Z]/},
                        'A': {pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase()},
                        'a': {pattern: /[a-zA-Z]/, transform: v => v.toLocaleLowerCase()},
                        '!': {escape: true}
                    }
                }
            }
        },
        methods: {
            onSubmit(evt) {
                evt.preventDefault()
                this.$http.post('/api/v1/falcons', this.falcon).then(
                    response => {
                        this.err = ''
                        this.msg = 'Created Falcon '+response.data.id
                    }
                ).catch( error => {
                    this.$nextTick(() => {
                        this.err = error.response.data.error.message
                        this.msg = ''
                    })
                })
            },
            onReset(evt) {
                evt.preventDefault()
                // Reset our form values
                this.falcon = {}
                this.msg = ''
                this.err = ''
                // Trick to reset/clear native browser form validation state
                this.show = false
                this.$nextTick(() => {
                    this.show = true
                })
            }
        }
    }
</script>

<style scoped>

</style>