<template>
    <div class="bg-white">
        <h3 class="float-left">Falcons</h3>
        <router-link class="float-right mt-1 mr-1 mb-2" to="/admin/falcons/create"><b-button variant="success">Create</b-button></router-link>
        <b-table striped hover bordered responsive :items="falcons" :fields="fields">
            <template v-slot:cell(url)="data">
                <b-link target="_blank" :to='"/details/"+data.item.hash'>https://falcons.digital/details/{{ data.item.hash }}</b-link>
            </template>
            <template v-slot:cell(created_at)="data">
                <span v-b-tooltip.hover.bottom :title="data.item.created_at | formatDate | moment('from','now')">{{ data.item.created_at | formatDate('YYYY-MM-DD HH:mm') }}</span>
            </template>
            <template v-slot:cell(updated_at)="data">
                <span v-b-tooltip.hover.bottom :title="data.item.updated_at | formatDate | moment('from','now')">{{ data.item.updated_at | formatDate('YYYY-MM-DD HH:mm') }}</span>
            </template>
            <template v-slot:cell(edit)="data">
                <b-link :to='"/admin/users/"+data.item.id+"/edit"'><b-button variant="warning" size="sm"><b-icon icon="pencil"></b-icon></b-button></b-link>
                <b-link :to='"/admin/media/"+data.item.id'><b-button variant="light" size="sm"><b-icon icon="images"></b-icon></b-button></b-link>
            </template>
        </b-table>
    </div>
</template>

<script>
    export default {
        name: "Users"
    }
</script>

<style scoped>

</style>