<template>
    <div class="bg-white pt-5 mb-5">
        <b-row>
            <b-col md="6" offset-md="3">
                <h2 v-if="this.$store.getters.isAdmin">Edit Falcon</h2>
                <h2 v-if="!this.$store.getters.isAdmin">Dashboard</h2>
            </b-col>
        </b-row>
        <Edit v-if="fid" :falcon-id="fid"></Edit>
        <UploadFiles :falcon-id="fid" title-str="Portfolio" :category="1"></UploadFiles>
        <UploadFiles :falcon-id="fid" title-str="Bio" :category="2" accept-type="image/*"></UploadFiles>
        <UploadFiles :falcon-id="fid" title-str="Health" :category="3" accept-type="image/*"></UploadFiles>
    </div>
</template>

<script>
    import Edit from "./Edit";
    import UploadFiles from "./UploadFiles";

    export default {
        name: "ControlPanel",
        components: {UploadFiles, Edit},
        computed: {
            fid() {
                return this.$store.getters.hasFalcon
            }
        },
        data() {
            return {

            }
        }
    }
</script>

<style scoped>

</style>