<template>
    <div>
        <b-navbar toggleable="lg" type="dark" variant="dark">
            <b-navbar-brand href="/">
                <img src="@/assets/logo-digital.png" height="30px">
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav>
                    <b-nav-item v-if="this.$store.getters.isAdmin" to="/admin/falcons">Falcons</b-nav-item>
                    <b-nav-item v-if="this.$store.getters.isAdmin && false" to="/admin/users">Users</b-nav-item>
                    <b-nav-item v-if="this.$store.getters.isLoggedIn && this.$store.getters.hasFalcon" to="/dashboard">Dashboard</b-nav-item>
                    <b-nav-item class="text-light" v-show="this.$store.getters.hasFalcon" :to="`/falcon/`+this.$store.getters.hasFalcon">Portfolio</b-nav-item>
                    <b-nav-item class="text-light" v-show="isDetail && !this.$store.getters.isLoggedIn" :to="`/details/`+this.$route.params.hash">Portfolio</b-nav-item>
                </b-navbar-nav>

                <!-- Right aligned nav items -->
                <b-navbar-nav class="ml-auto">
                    <b-navbar-nav>
                        <b-nav-item v-if="!this.$store.getters.isLoggedIn" :to="{ name: 'login' }">Login</b-nav-item>
                        <b-nav-item v-if="this.$store.getters.isLoggedIn" :to="{ name: 'logout' }">Logout</b-nav-item>
                    </b-navbar-nav>
                    <!--<b-nav-form>
                        <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>
                        <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>
                    </b-nav-form>

                    <b-nav-item-dropdown text="Lang" right>
                        <b-dropdown-item href="#">EN</b-dropdown-item>
                        <b-dropdown-item href="#">ES</b-dropdown-item>
                        <b-dropdown-item href="#">RU</b-dropdown-item>
                        <b-dropdown-item href="#">FA</b-dropdown-item>
                    </b-nav-item-dropdown>-->

                    <!-- <b-nav-item-dropdown right>
                        Using 'button-content' slot
                        <template v-slot:button-content>
                            <em>User</em>
                        </template>
                        <b-dropdown-item href="#">Profile</b-dropdown-item>
                        <b-dropdown-item href="#">Sign Out</b-dropdown-item>
                    </b-nav-item-dropdown>-->
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
    export default {
        name: "Navbar",
        computed: {
            isDetail() {
                if (this.$route.name === 'details' || this.$route.name === 'weights' || this.$route.name === 'bio' || this.$route.name === 'health') {
                    return true;
                } else {
                    return false;
                }
            },
        }
    }
</script>

<style scoped>
    #path7, #path9, #path11, #path13 {
        fill:white;
    }
    .navbar-dark .navbar-nav .nav-link {
        color: white;
        font-size: 1.25em;
        font-family: 'Eurostile';
    }
</style>