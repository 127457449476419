<template>
    <div>
        <b-img-lazy class="image" thumbnail v-if="isImage(type)" :src="thumbnail" fluid-grow show></b-img-lazy>
        <div class="video-wrapper"  v-if="!isImage(type)">
            <b-img-lazy thumbnail class="image" :src="thumbnail" fluid-grow show></b-img-lazy>
            <b-iconstack class="image-play-button text-light" font-scale="3">
                <b-icon stacked icon="circle"></b-icon>
                <b-icon stacked icon="play-fill" shift-h="1"></b-icon>
            </b-iconstack>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MediaItem",
        components: {
        },
        data() {
            return {

            }
        },
        props: {
            thumbnail: String,
            path: String,
            type: String
        },
        methods: {
            isImage(chkstr) {
                return chkstr.includes('image')
            }
        }
    }
</script>

<style scoped>
    .image-play-button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
    }
</style>