import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import VueHead from 'vue-head'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import createPersistedState from 'vuex-persistedstate'
import VueTheMask from 'vue-the-mask'
import VueImg from 'v-img';
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VueScrollTo from 'vue-scrollto'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import {VueMasonryPlugin} from 'vue-masonry';
import VueConfirmDialog from 'vue-confirm-dialog'
//import VueMatomo from 'vue-matomo'

// name is optional
Vue.use(VueLodash, { name: 'ld' , lodash: lodash })
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(VueAxios, axios)
Vue.use(VueTheMask)
Vue.use(VueHead)
Vue.use(VueImg)
Vue.use(VueScrollTo)
Vue.use(VueMasonryPlugin)
Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
/*Vue.use(VueMatomo, {
  // Configure your matomo server and site by providing
  host: 'https://streamad.info/peperoni',
  siteId: 4,

  // Changes the default .js and .php endpoint's filename
  // Default: 'matomo'
  trackerFileName: 'matomo',

  // Overrides the autogenerated tracker endpoint entirely
  // Default: undefined
  // trackerUrl: 'https://example.com/whatever/endpoint/you/have',

  // Overrides the autogenerated tracker script path entirely
  // Default: undefined
  // trackerScriptUrl: 'https://example.com/whatever/script/path/you/have',

  // Enables automatically registering pageviews on the router
  router: router,

  // Enables link tracking on regular links. Note that this won't
  // work for routing links (ie. internal Vue router links)
  // Default: true
  enableLinkTracking: true,

  // Require consent before sending tracking information to matomo
  // Default: false
  requireConsent: false,

  // Whether to track the initial page view
  // Default: true
  trackInitialView: false,

  // Run Matomo without cookies
  // Default: false
  disableCookies: false,

  // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
  // Default: false
  enableHeartBeatTimer: true,

  // Set the heartbeat timer interval
  // Default: 15
  heartBeatTimerInterval: 15,

  // Whether or not to log debug information
  // Default: false
  debug: false,

  // UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
  // Default: undefined
  userId: undefined,

  // Share the tracking cookie across subdomains (see https://developer.matomo.org/guides/tracking-javascript-guide#measuring-domains-andor-sub-domains)
  // Default: undefined, example '*.example.com'
  cookieDomain: '*.falcons.digital',

  // Tell Matomo the website domain so that clicks on these domains are not tracked as 'Outlinks'
  // Default: undefined, example: '*.example.com'
  domains: '*.falcons.digital',

  // A list of pre-initialization actions that run before matomo is loaded
  // Default: []
  // Example: [
  //   ['API_method_name', parameter_list],
  //   ['setCustomVariable','1','VisitorType','Member'],
  //   ['appendToTrackingUrl', 'new_visit=1'],
  //   etc.
  // ]
  preInitActions: []
});*/

moment.tz.guess();
Vue.use(VueMoment, {
  moment
});

import Login from "./components/Login"
import Logout from "./components/Logout"
import MediaUpload from "./components/MediaUpload"
import Falcons from "./components/Falcons"
import Create from "./components/Create"
import Edit from "./components/Edit"
import Weights from "./components/Weights"
import Details from "./components/Details"
import UploadFiles from "./components/UploadFiles"
import ControlPanel from "./components/ControlPanel"
import Users from "./components/Users"
import CreateUser from "./components/CreateUser"
import EditUser from "./components/EditUser"
import Bio from "./components/Bio.vue"
import Health from "./components/Health.vue"
import Impressum from "./components/Impressum.vue"

const routes = [
  { path: '/', name: 'home', component: Login },
  { path: '/impressum', name: 'impressum', component: Impressum },
  { path: '/dist', name: 'root', component: Login },
  { path: '/login', name: 'login', component: Login },
  { path: '/login/:user', name: 'loginUser', component: Login },
  { path: '/code/:user', name: 'code', component: Login },
  { path: '/logout', name: 'logout', component: Logout },
  { path: '/logout/:user', name: 'logoutUser', component: Logout },
  { path: '/upload', name: 'upload', component: MediaUpload, meta: { requiresAuth: true } },
  { path: '/dashboard', name: 'dashboard', component: ControlPanel, meta: { requiresAuth: true } },
  { path: '/admin/users', name: 'users', component: Users, meta: { requiresAdmin: true } },
  { path: '/admin/users/create', name: 'createUser', component: CreateUser, meta: { requiresAdmin: true } },
  { path: '/admin/users/:id/edit', name: 'editUser', component: EditUser, meta: { requiresAdmin: true } },
  { path: '/admin/falcons', name: 'falcons', component: Falcons, meta: { requiresAdmin: true } },
  { path: '/admin/falcons/create', name: 'create', component: Create, meta: { requiresAdmin: true } },
  { path: '/admin/falcons/:id/edit', name: 'edit', component: Edit, meta: { requiresAdmin: true } },
  { path: '/admin/media/:id', name: 'media', component: UploadFiles,  props: { titleStr: 'Portfolio', category: 1, acceptType: "image/*" }, meta: { requiresAdmin: true } },
  { path: '/admin/bio/:id', name: 'mediaBio', component: UploadFiles, props: { titleStr: 'Bio', category: 2, acceptType: "image/*" }, meta: { requiresAdmin: true } },
  { path: '/admin/health/:id', name: 'mediaHealth', component: UploadFiles, props: { titleStr: 'Health', category: 3, acceptType: "image/*" }, meta: { requiresAdmin: true } },
  { path: '/details/:hash', name: 'details', component: Details},
  { path: '/details/:hash/weights', name: 'weights', component: Weights},
  { path: '/details/:hash/bio/:user', name: 'bioF', component: Bio, meta: { requiresFalconAuth: true } },
  { path: '/details/:hash/health/:user', name: 'healthF', component: Health, meta: { requiresFalconAuth: true } },
  { path: '*', name: 'error' },
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: function(to, from, savedPosition) {
    if (to.hash) {
      return {selector: to.hash}
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

const getDefaultState = () => {
  return {
    token: '',
    user: {}
  };
};

const store = new Vuex.Store({
  strict: true,
  plugins: [createPersistedState()],
  state: getDefaultState(),
  getters: {
    isLoggedIn: state => {
      return state.token;
    },
    getUser: state => {
      return state.user;
    },
    isAdmin: state => {
      return state.user.admin;
    },
    hasFalcon: state => {
      return state.user.falcon;
    }
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_USER: (state, user) => {
      state.user = user;
    },
    RESET: state => {
      Object.assign(state, getDefaultState());
    }
  },
  actions: {
    /* eslint-disable no-unused-vars */
    login: ({ commit,dispatch }, { token, user }) => {
      commit('SET_TOKEN', token);
      commit('SET_USER', user);
      // set auth header
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    },
    logout: ({ commit }) => {
      commit('RESET', '');
    }
    /* eslint-enable no-unused-vars */
  }
})

Vue.config.productionTip = false

axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.token}`;

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresFalconAuth)) {
    //console.log(store.getters.getUser.name, to.params.user)
    if (store.getters.isLoggedIn && (store.getters.getUser.name === to.params.user || store.getters.isAdmin)) {
      next()
      return
    } else if(store.getters.isLoggedIn && (store.getters.getUser.name !== to.params.user || !store.getters.isAdmin)) {
      next({
        path: '/logout/'+(to.params.user?to.params.user:null),
        query: { redirect: to.fullPath }
      })
    } else {
      next({
        path: '/login/'+(to.params.user?to.params.user:null),
        query: { redirect: to.fullPath }
      })
    }
  } else if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    } else {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    }
  } else if(to.matched.some(record => record.meta.requiresAdmin)) {
    if (store.getters.isAdmin) {
      next()
      return
    } else {
      next({
        path: '/login',
        query: {redirect: to.fullPath}
      })
    }
  } else {
    next()
  }
})

Vue.filter('formatDate', function(date, format) {
    return moment.utc(date).local().format(format)
})

new Vue({
  render: h => h(App),
  store: store,
  router: router,

}).$mount('#app')
