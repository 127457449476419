<template>
    <div>
        <IconMenu :falcon-id="detail.id"></IconMenu>
        <div v-if="loaded">
            <b-container class="bg-dark">
                <b-row align-v="center">
                    <b-col class="col-12 col-md-6">
                        <h1 class="center text-center text-white mt-5">{{ weightVal }} g</h1>
                        <p class="center text-center text-white mb-5">
                            <span v-b-tooltip.hover.bottom.v-dark :title="weightValTs | moment('from','now')">{{ weightValTs | moment('dddd, Do MMMM YYYY') }}</span>
                        </p>
                    </b-col>
                    <b-col class="col-12 col-md-6">
                        <line-chart class="" v-if="loaded" :chartdata="weights" :chartlabels="labels" />
                    </b-col>
                </b-row>
                <b-table id="weightTable" dark v-if="loaded" class="mt-5" striped responsive hover :items="weightData" :fields="fields" :per-page="perPage" :current-page="currentPage">
                    <template v-slot:cell(weight_taken)="data">
                        <span v-b-tooltip.hover.bottom :title="data.item.weight_taken | formatDate | moment('from','now')">{{ data.item.weight_taken | formatDate('YYYY-MM-DD HH:mm') }}</span>
                    </template>
                    <template v-slot:cell(weight)="data">
                        {{ data.item.weight }}
                    </template>
                </b-table>
                <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        aria-controls="weightTable"
                        align="center"
                        pills
                        hide-goto-end-buttons
                ></b-pagination>
            </b-container>
        </div>
    </div>
</template>

<script>
    import LineChart from "./Chart.vue"
    import IconMenu from "./IconMenu";

    export default {
        name: "Weights",
        components: {
            IconMenu,
            LineChart
        },
        props: {
            chartdata: {
                type: Object,
                default: null
            },
            options: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                loaded: false,
                detail: {
                    name: null,
                    zuechter_name: null,
                    zuechter_url: null,
                    title_image: null
                },
                perPage: 10,
                currentPage: 1,
                labels: [],
                weights: [],
                weightData: [],
                fields: [
                    {
                        key: 'weight_taken',
                        label: 'Date',
                        sortable: true
                    },
                    {
                        key: 'weight',
                        sortable: true
                    }
                ]
            }
        },
        mounted () {
            this.loaded = false
            try {
                this.fetchData()
            } catch(e) {
                console.log(e)
            }

        },
        watch: {
            '$route': 'fetchData',
            chartData () {
                this.$data._chart.update()
            }
        },
        methods: {
            dateConv : function( timestamp ) {
                return this.$moment.utc( timestamp ).local().format( 'YYYY-MM-DD HH:mm' );
            },
            async fetchData () {
                this.loaded = false
                await this.$http.get('/api/v1/details/'+this.$route.params.hash)
                    .then(response => (this.detail = response.data))
                    .catch(error => console.log(error))
                await this.$http.get('/api/v1/weight/uid/'+this.detail.uid)
                    .then(response => {
                        this.weightData = response.data
                        this.labels = response.data.map(weight => this.dateConv(weight.weight_taken)).slice(0,10).reverse()
                        this.weights = response.data.map(weight => weight.weight).slice(0,10).reverse()
                        if(this.weights.length === 0) {
                            this.loaded = false;
                        } else {
                            this.loaded = true
                        }
                    })
                    .catch(error => console.log(error))

            }
        },
        computed: {
            weightVal: function() {
                return this.weights[this.weights.length - 1]
            },
            weightValTs: function() {
                return this.labels[this.labels.length - 1]
            },
            rows() {
                return this.weightData.length
            }
        }
    }
</script>

<style scoped>

</style>