<template>
    <div class="card mb-5">
        <div class="card-header">{{ title }}</div>
        <div class="card-body">
            <div>
                <cropper
                        ref="cropper"
                        v-if="imageNew"
                        :src="imageNew"
                        :stencilProps="stencilRatio"
                        :stencil-component="stencilType"
                        :restrictions="restrictions"
                />
                <b-img-lazy fluid-grow v-if="!imageNew" :src="image"></b-img-lazy>
                <div v-if="progress" class="progress">
                    <div
                            class="progress-bar progress-bar-light progress-bar-striped"
                            role="progressbar"
                            :aria-valuenow="progress"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            :style="{ width: progress + '%' }"
                    >
                        {{ progress }}%
                    </div>
                </div>
                <b-button-group class="mt-2">
                    <b-button @click="uploadImage" variant="success" :disabled="!imageNew">Save</b-button>
                    <b-button @click="cancelEditMode" :disabled="!imageNew">Cancel</b-button>
                    <b-button @click="$refs.file.click()">
                        <form style="display:none;" ref="form"><input style="display:none;" type="file" ref="file" @change="loadImage($event)" accept="image/*"></form>
                        Select new image
                    </b-button>
                </b-button-group>
                <div v-show="message" class="alert alert-light" role="alert">{{ message }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import UploadService from "../UploadService";
    import {
        Cropper
    } from 'vue-advanced-cropper';

    export default {
        name: "UploadImage",
        components: {
            Cropper
        },
        props: {
            image: {
                type: String,
                default: ''
            },
            endpoint: {
                type: String,
                default: ''
            },
            title: {
                type: String,
                default: 'Upload image'
            },
            stencilType: {
                type: String,
                default: 'rectangle-stencil'
            },
            stencilRatio: {
                type: Object,
                default: function () {
                    return { aspectRatio: 16/9 }
                }
            },
            restrictions: {
                type: Function,
                default: function () {
                    return {}
                }
            }
        },
        data() {
            return {
                message: '',
                progress: 0,
                imageNew: null
            }
        },
        beforeDestroy() {
            this.$refs.form.reset()
        },
        methods: {
            cancelEditMode() {
                this.imageNew = null
                this.$refs.form.reset()
            },
            loadImage(event) {
                // Reference to the DOM input element
                var input = event.target;
                // Ensure that you have a file before attempting to read it
                if (input.files && input.files[0]) {
                    // create a new FileReader to read this image and convert to base64 format
                    var reader = new FileReader();
                    // Define a callback function to run, when FileReader finishes its job
                    reader.onload = (e) => {
                        // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
                        // Read image as base64 and set to imageData
                        this.imageNew = e.target.result;
                    };
                    // Start the reader job - read file as a data url (base64 format)
                    reader.readAsDataURL(input.files[0]);
                }
            },
            uploadImage() {
                this.progress = 0;
                const { canvas } = this.$refs.cropper.getResult();
                if(canvas) {
                    canvas.toBlob(blob => {
                        let formData = new FormData()
                        formData.append('file',blob)
                        UploadService.uploadHeaderImage(this.endpoint, formData, event => {
                            this.progress = Math.round((100 * event.loaded) / event.total);
                        }).then(response => {
                                this.progress = 0
                                this.message = response.data.message
                                this.image = response.data.image
                                this.imageNew = null
                                this.$refs.form.reset()
                                formData = null
                        })
                        .catch(() => {
                            this.progress = 0;
                            this.imageNew = null
                            this.$refs.file.reset()
                            formData = null
                            this.message = "Could not upload the image!";
                        });
                        this.progress = 0;
                    }, 'image/jpeg')
                }
            },
        }
    }
</script>

<style scoped>
    .button-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 17px;
    }

    .button {
        color: white;
        font-size: 16px;
        padding: 10px 20px;
        background: #3fb37f;
        cursor: pointer;
        transition: background 0.5s;
    }

    .button:hover {
        background: #38d890;
    }

    .button input {
        display: none;
    }
</style>