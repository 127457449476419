<template>
    <div class="card">
        <div class="card-header">Weights</div>
        <div class="card-body">
            <b-table id="weightTable" v-if="loaded" striped responsive hover :items="weightData" :fields="fields" :per-page="perPage" :current-page="currentPage">
              <template v-slot:cell(weight_taken)="data">
                <span v-b-tooltip.hover.bottom :title="data.item.weight_taken | formatDate | moment('from','now')">{{ data.item.weight_taken | formatDate('YYYY-MM-DD HH:mm') }}</span>
              </template>
              <template v-slot:cell(weight)="data">
                {{ data.item.weight }}
              </template>
              <template v-slot:cell(edit)="data">
                <b-icon class="float-right" @click="onDelete(data.item.id, $event)" icon="trash" style="cursor: pointer;"></b-icon>
              </template>
            </b-table>
            <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="weightTable"
                align="center"
                pills
                hide-goto-end-buttons
            ></b-pagination>
            <h6>Insert new weight</h6>
            <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                <b-form-group
                        id="input-group-1"
                        label="Weight:"
                        label-for="input-1"
                >
                    <b-form-input
                            id="input-1"
                            v-model="weight"
                            placeholder="Weight"
                            type="number"
                            required
                    ></b-form-input>
                </b-form-group>

                <label for="input-datepicker">Date</label>
                <b-form-datepicker id="input-datepicker" v-model="weight_taken" :max="max" required class="mb-3"></b-form-datepicker>

                <div>
                    <b-button-group>
                        <b-button type="submit" variant="success">Insert</b-button>
                        <b-button type="reset" variant="secondary">Reset</b-button>
                    </b-button-group>
                </div>
            </b-form>
        </div>
        <b-alert show v-if="err" class="mt-3" variant="danger" >Error: {{ err }}</b-alert>
        <b-card v-if="msg" class="mt-3" :header="msg">
            <b-button type="button" v-on:click="$router.go(-1)" variant="light">Back</b-button>
        </b-card>
    </div>

</template>

<script>
    export default {
        name: "CreateWeight",
        data () {
            const now = new Date()
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
            const maxDate = new Date(today)
            return {
                loaded: false,
                max: maxDate,
                weight: null,
                weight_taken: null,
                show: true,
                msg: '',
                err: '',
                perPage: 5,
                currentPage: 1,
                weightData: [],
                fields: [
                  {
                    key: 'weight_taken',
                    label: 'Date',
                    sortable: true
                  },
                  {
                    key: 'weight',
                    sortable: true
                  },
                  {
                    key: 'edit',
                    label: '',
                    sortable: false
                  },
                ]
            }
        },
        mounted () {
          this.fetchData()
        },
        props: {
            falconUid: {
                type: String,
                default: null
            }
        },
        computed: {
            fullDate () {
              return this.weight_taken+' 12:00:00'
            },
            rows() {
              return this.weightData.length
            }
        },
        methods: {
            onSubmit(evt) {
                evt.preventDefault()
                if(!this.weight_taken) {
                    alert('Please select a date when the weight was taken!')
                    return
                }
                if(!this.falconUid) {
                    console.log('missing falcon uid')
                    return
                }
                this.$http.post('/api/v1/weight', { uid: this.falconUid, weight: this.weight, weight_taken: this.fullDate }).then(
                    response => {
                        this.msg = 'Inserted Weight '+response.data.id
                        this.weight = null
                        this.date = null
                        this.show = false
                        this.$nextTick(() => {
                            this.show = true
                          this.fetchData()
                        })
                    }
                ).catch(
                    error => {
                        this.$nextTick(() => {
                            this.err = error.response.data.error.message
                            this.msg = ''
                        })
                    })
            },
            onReset(evt) {
                evt.preventDefault()
                this.weight = null
                this.date = null
                this.show = false
                this.$nextTick(() => {
                    this.show = true
                })
            },
            async fetchData () {
                await this.$http.get('/api/v1/weight/uid/'+this.falconUid)
                    .then(response => {
                      this.weightData = response.data
                      if(this.weightData) this.loaded = true
                    })
                    .catch(error => console.log(error))

            },
            onDelete(id, evt) {
              evt.preventDefault()
              this.$confirm(
                  {
                    message: `Are you sure you want to delete this weight?`,
                    button: {
                      no: 'No',
                      yes: 'Yes'
                    },
                    callback: confirm => {
                      if (confirm) {
                        this.$http.delete('/api/v1/weight/'+id)
                            .then(response => {
                              this.msg = response.data;
                              this.fetchData()
                            })
                            .catch(error => console.log(error))
                      }
                    }
                  }
              )
            },
        }
    }
</script>

<style scoped>

</style>