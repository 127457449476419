<template>
    <b-row align-h="center" class="icon-menu mt-3 mb-3">
        <div class="h1 mb-0 pr-3 pl-3 text-light">
            <b-link :to="`/details/`+this.$route.params.hash">
                <b-icon scale="0.9" icon="grid"></b-icon>
            </b-link>
        </div>
        <div class="h1 mb-0 pr-3 pl-3 text-light">
            <b-link :to="`/details/`+this.$route.params.hash+`/weights`">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="-3 -2 25 25" class="bi-grid b-icon bi" role="img" fill="currentColor">
                    <title>scale2</title>
                    <path d="M10 11.086v-1.586c0-0.276-0.224-0.5-0.5-0.5s-0.5 0.224-0.5 0.5v1.586c-0.582 0.206-1 0.762-1 1.414 0 0.827 0.673 1.5 1.5 1.5s1.5-0.673 1.5-1.5c0-0.652-0.418-1.208-1-1.414zM9.5 13c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5 0.5 0.224 0.5 0.5-0.224 0.5-0.5 0.5z"></path>
                    <path d="M9.5 8c-2.481 0-4.5 2.019-4.5 4.5s2.019 4.5 4.5 4.5c2.481 0 4.5-2.019 4.5-4.5s-2.019-4.5-4.5-4.5zM10 15.964v-0.464c0-0.276-0.224-0.5-0.5-0.5s-0.5 0.224-0.5 0.5v0.464c-1.531-0.22-2.744-1.434-2.964-2.964h0.464c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-0.464c0.243-1.694 1.704-3 3.464-3s3.221 1.306 3.464 3h-0.464c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h0.464c-0.22 1.531-1.434 2.744-2.964 2.964z"></path>
                    <path d="M17.5 0h-16c-0.276 0-0.5 0.224-0.5 0.5v1c0 1.378 1.122 2.5 2.5 2.5h4.5v1.149c-1.431 0.289-2.747 0.991-3.803 2.047-1.417 1.417-2.197 3.3-2.197 5.303v6c0 0.827 0.673 1.5 1.5 1.5h12c0.827 0 1.5-0.673 1.5-1.5v-6c0-2.003-0.78-3.887-2.197-5.303-1.056-1.056-2.372-1.758-3.804-2.047l-0.001-1.149h4.501c1.378 0 2.5-1.122 2.5-2.5v-1c0-0.276-0.224-0.5-0.5-0.5zM16 12.5v6c0 0.276-0.224 0.5-0.5 0.5h-12c-0.276 0-0.5-0.224-0.5-0.5v-6c0-3.584 2.916-6.5 6.5-6.5s6.5 2.916 6.5 6.5zM10 5.017c-0.166-0.011-0.332-0.017-0.5-0.017s-0.334 0.006-0.5 0.017v-1.017h0.999l0.001 1.017zM17 1.5c0 0.827-0.673 1.5-1.5 1.5h-12c-0.827 0-1.5-0.673-1.5-1.5v-0.5h15v0.5z"></path>
                </svg>

            </b-link>
        </div>
        <div class="h1 mb-0 pr-3 pl-3 text-light">
            <b-link :to="`/details/`+this.$route.params.hash+`/bio/owner`+falconId">
                <b-icon scale="0.9" icon="file-text"></b-icon>
            </b-link>
        </div>
        <div class="h1 mb-0 pr-3 pl-3 text-light">
            <b-link :to="`/details/`+this.$route.params.hash+`/health/owner`+falconId">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="-3 1 20 20" class="bi-grid b-icon bi" role="img" fill="currentColor">
                    <title>first-aid</title>
                    <path d="M11.5 16h-3c-0.276 0-0.5-0.224-0.5-0.5v-1.5h-1.5c-0.276 0-0.5-0.224-0.5-0.5v-3c0-0.276 0.224-0.5 0.5-0.5h1.5v-1.5c0-0.276 0.224-0.5 0.5-0.5h3c0.276 0 0.5 0.224 0.5 0.5v1.5h1.5c0.276 0 0.5 0.224 0.5 0.5v3c0 0.276-0.224 0.5-0.5 0.5h-1.5v1.5c0 0.276-0.224 0.5-0.5 0.5zM9 15h2v-1.5c0-0.276 0.224-0.5 0.5-0.5h1.5v-2h-1.5c-0.276 0-0.5-0.224-0.5-0.5v-1.5h-2v1.5c0 0.276-0.224 0.5-0.5 0.5h-1.5v2h1.5c0.276 0 0.5 0.224 0.5 0.5v1.5z"></path>
                    <path d="M18.5 5h-4.5v-1.5c0-0.827-0.673-1.5-1.5-1.5h-5c-0.827 0-1.5 0.673-1.5 1.5v1.5h-4.5c-0.827 0-1.5 0.673-1.5 1.5v11c0 0.827 0.673 1.5 1.5 1.5h17c0.827 0 1.5-0.673 1.5-1.5v-11c0-0.827-0.673-1.5-1.5-1.5zM7 3.5c0-0.276 0.224-0.5 0.5-0.5h5c0.276 0 0.5 0.224 0.5 0.5v1.5h-6v-1.5zM19 17.5c0 0.276-0.224 0.5-0.5 0.5h-17c-0.276 0-0.5-0.224-0.5-0.5v-11c0-0.276 0.224-0.5 0.5-0.5h17c0.276 0 0.5 0.224 0.5 0.5v11z"></path>
                </svg>
            </b-link>
        </div>
    </b-row>
</template>

<script>
    export default {
        name: "IconMenu",
        props: {
          falconId: null
        }
    }
</script>

<style scoped>
    .white-icon {
        fill:white;
    }
    .icon-menu a, .icon-menu a:visited {
        color: white !important;
    }
    .icon-menu a:hover, .icon-menu a:active, .icon-menu a.router-link-exact-active {
        color: grey !important;
    }
</style>