<template>
    <div class="bg-dark">
        <b-container v-if="detail.title_image" fluid class="pl-0 pr-0 justify-content-center text-center">
            <div class="gradient-effect ">
                <b-img-lazy fluid-grow :src="detail.title_image" :srcset="src2srcset(detail.title_image)" sizes="100vw" show></b-img-lazy>
            </div>
        </b-container>
        <b-container>
            <IconMenu :falcon-id="detail.id"></IconMenu>
            <b-row>
                <b-col style="margin-top: 3em; margin-bottom: 5em; !important;">
                    <h1 class="text-light">{{ detail.name }}</h1>
                    <hr>
                    <p><b-link class="text-light" :href="detail.zuechter_url" target="_blank">{{ detail.zuechter_name }}</b-link></p>
                </b-col>
            </b-row>
            <vue-gallery :images="mediaArrayVg" :options=galOptions :index="index" @close="index = null"/>
            <b-row id="gallerymasonry" class="images-wrapper masonry-container gallerymasonry" v-masonry="`gallerymasonry`" transition-duration="0.3s" item-selector=".item">
                <b-col v-masonry-tile cols="6" md="4" class="item mb-2 pr-1 pl-1" v-for="(image, imageIndex) in sortedMedia" :key="imageIndex" @click="setIndex(imageIndex)" style="cursor: pointer;">
                    <b-img-lazy class="image" v-if="isImage(image.type)" :src="image.thumbnail" fluid-grow blank show v-images-loaded:on.progress="imageProgress"></b-img-lazy>
                    <div class="video-wrapper"  v-if="isVideo(image.type)">
                        <b-img-lazy class="image" :src="image.thumbnail" fluid-grow blank show v-images-loaded:on.progress="imageProgress"></b-img-lazy>
                        <b-iconstack class="image-play-button text-light" font-scale="3">
                            <b-icon stacked icon="circle"></b-icon>
                            <b-icon stacked icon="play-fill" shift-h="1"></b-icon>
                        </b-iconstack>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import vueGallery from 'vue-gallery'
    import imagesLoaded from "vue-images-loaded"
    import IconMenu from "./IconMenu";

    export default {
        name: "Details",
        components: {
            IconMenu,
            vueGallery
        },
        directives: {
            imagesLoaded
        },
        data() {
            return {
                loaded: false,
                detail: {
                    id: null,
                    name: null,
                    zuechter_name: null,
                    zuechter_url: null,
                    title_image: null
                },
                media: [],
                index: null,
                galOptions: {
                    fullscreen: true,
                    closeOnSwipeUpOrDown: false,
                    videoPlaysInline: true,
                    videoCoverClass: 'video-cover',
                    toggleControlsOnSlideClick: false,
                    stopTouchEventsPropagation: true,
                    youTubePlayerVars: {
                        autoPlay: true,
                        controls: false,
                        fs: false,
                        rel: 0
                    },
                    youTubeClickToPlay: false,
                }
            }
        },
        mounted () {
            try {
                this.fetchData()
            } catch(e) {
                console.log(e)
            }
            document.addEventListener('vuemasonry.itemAdded__gallerymasonry', this.redrawGrid)
            this.redrawGrid()
        },
        beforeDestroy() {
            document.removeEventListener('vuemasonry.itemAdded__gallerymasonry', this.redrawGrid)
        },
        watch: {
            '$route': 'fetchData',
            scrollDisable: function() {
                if(this.scrollDisable){
                    document.documentElement.style.overflow = 'hidden'
                    return
                }
                document.documentElement.style.overflow = 'auto'
            }
        },
        methods: {
            src2srcset: function(imgOriginal) {
                let imgPath = String(imgOriginal).replace('/media/','')
                return '/image/720/'+imgPath+' 700w, /image/1280/'+imgPath+' 1200w, /image/1440/'+imgPath+' 1400w, /image/1600/'+imgPath+' 1500w, /image/1920/'+imgPath+' 1900w, /image/2560/'+imgPath+' 2500w, /image/2960/'+imgPath+' 2900w, /image/2560/'+imgPath+' 2x, /media/'+imgPath+' 3x'
            },
            setIndex(index) {
                this.index = index
            },
            async fetchData () {
                await this.$http.get('/api/v1/details/'+this.$route.params.hash)
                    .then(response => {
                        this.detail = response.data
                        this.loaded = true
                    })
                    .catch(error => console.log(error))
                await this.$http.get('/api/v1/media/1/'+this.detail.id)
                    .then(response => {
                        this.media = response.data
                        this.redrawGrid()
                    })
                    .catch(error => console.log(error))
            },
            isImage(chkstr) {
                return chkstr.includes('image')
            },
            isVideo(chkstr) {
                return chkstr.includes('video')
            },
            imageProgress(instance, image ) {
                if (image.isLoaded) this.$nextTick(() => this.$redrawVueMasonry('gallerymasonry'));
            },
            redrawGrid() {
                this.$nextTick(() => this.$redrawVueMasonry('gallerymasonry'));
            }
        },
        computed: {
            scrollDisable: function () {
                return this.index != null
            },
            sortedMedia: function() {
                return this._.orderBy(this.media, 'id', 'desc')
            },
            mediaArray: function() {
                let tempArr
                tempArr = this.sortedMedia.map(item=> {
                        return {
                            src: item.path,
                            thumb: item.thumbnail
                        }
                    }
                )
                return tempArr
            },
            mediaArrayVg: function() {
                return this._.orderBy(this.sortedMedia, 'id', 'desc').map(item=> {
                    if(item.type.includes('video')) {
                        return {
                            type: 'text/html',
                            href: item.path.replace('https://youtube.com/watch?v=','https://youtube-nocookie.com/watch?v='),
                            youtube: item.path.replace('https://youtube.com/watch?v=',''),
                            poster: item.thumbnail
                        }
                    } else {
                        return {
                            type: item.type,
                            href: item.path,
                            srcset: this.src2srcset(item.path),
                            sizes: '(min-width: 990px) 990px, 100vw',
                            thumbnail: item.thumbnail
                        }
                    }
                })
            },
            mediaArrayOld: function() {
                return this._.orderBy(this.sortedMedia, 'sort_number', 'desc').map(item=> item.path)
            },
        }
    }
</script>

<style scoped>
    hr {
        margin-top: 1rem;
        margin-bottom: 1rem;
        margin-right: 10rem;
        margin-left:0;
        max-width: 10rem;
        left:0;
        border: 0;
        border-top: 1px solid white;
    }
    .image-play-button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
    }
</style>

<style>
    .blueimp-gallery {
        background: #2E2D2C;
    }
    .blueimp-gallery > .close {
        font-size: 2em !important;
    }
    .blueimp-gallery>.next {
      right: 5px !important;
    }
    .blueimp-gallery>.prev {
      left: 5px !important;
    }
    .blueimp-gallery>.next, .blueimp-gallery>.prev {
        margin-top:-0.2em;
        font-size: 3em !important;
        border: 0 !important;
        border-radius: 0 !important;
        background-color: transparent !important;
    }
    .blueimp-gallery>.slides>.slide>.video-content>img {

    }
    .blueimp-gallery>.slides>.slide>.video-content {
        padding-top: 56.25% !important;
    }
    .blueimp-gallery>.slides>.slide>.video-content>iframe {
        position:absolute;top:0;left:0;width:100%;height:100%;
    }
</style>