import axios from 'axios';

const url = '/api/v1/';

export default {
    login(credentials) {
        return axios
            .post(url + 'oauth/token', credentials)
            .then(response => response)
    },
    logout() {
        return axios
            .get(url + 'logout', )
            .then(response => response)
    },
    user(token) {
        return axios.get(url + 'user', { headers: { 'Authorization': 'Bearer ' + token}}).then(response => response)
    }
};