<template>
    <div class="bg-dark">
        <p class="text-light">{{msg}}</p>
    </div>
</template>

<script>
    import AuthService from '@/AuthService.js';

    export default {
        name: "Logout",
        data() {
            return {
                loginUser: '',
                loginPassword: '',
                msg: ''
            }
        },
        async mounted() {
            try {
                await AuthService.logout();
                this.$store.dispatch('logout');
                if(this.$route.params.user) {
                    this.$router.push('/code/'+this.$route.params.user);
                } else {
                    this.$router.push('/login');
                }
            } catch (error) {
                this.msg = error.response.data.message;
            }
        }
    }
</script>

<style scoped>

</style>