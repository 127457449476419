<template>
    <div>
        <!-- Styled -->
        <b-row>
            <b-col sm="6" offset-sm="3">
                <b-card>
                    <b-card-text>
                        <h2>Upload</h2>
                        <h6>Select multiple images and/or videos to upload.</h6>
                        <b-form-file
                                multiple
                                accept=".jpg, .png, .gif, .mp4, .avi, ."
                                v-model="file"
                                :state="Boolean(file)"
                                placeholder="Choose file(s)..."
                                drop-placeholder="Drop file(s) here..."
                        >
                            <template slot="file-name" slot-scope="{ names }">
                                <b-badge variant="dark">{{ names[0] }}</b-badge>
                                <b-badge v-if="names.length > 1" variant="dark" class="ml-1">
                                    + {{ names.length - 1 }} More files
                                </b-badge>
                            </template>
                        </b-form-file>
                        <b-button class="mt-2" variant="success" v-on:click="uploadFiles()">Upload</b-button>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    export default {
        name: "MediaUpload",
        data() {
            return {
                file: null
            }
        },
        methods: {
            formatNames(files) {
                if (files.length === 1) {
                    return files[0].name
                } else {
                    return `${files.length} files selected`
                }
            }
        }
    }
</script>

<style scoped>

</style>