<script>
    import { Line } from 'vue-chartjs'

    export default {
        extends: Line,
        props: {
            chartdata: {
                type: Array,
                required: false
            },
            chartlabels: {
                type: Array,
                required: true
            }
        },
        data () {
            return {
                options: {
                    scales: {
                        yAxes: [{
                            gridLines: {
                                display: false
                            },
                            position: 'right',
                            ticks: {
                                fontColor: '#FFF'
                            }
                        }],
                        xAxes: [ {
                            ticks: {
                                callback: function(value) {
                                    let str = value.toString();
                                    return str.substring(0,10);
                                },
                                minRotation: 45,
                                fontColor: '#FFF'
                            },
                            gridLines: {
                                display: false
                            },
                            scaleLabel: {
                                fontColor: '#FFF'
                            }
                        }]
                    },
                    legend: {
                        display: false,
                    },
                    responsive: true,
                    maintainAspectRatio: true
                }
            }
        },
        mounted () {
            this.renderChart({
                labels: this.chartlabels,
                datasets: [
                    {
                        label: 'Weight in Gramm',
                        fontColor: '#FFF',
                        borderColor: '#FFF',
                        pointBackgroundColor: 'white',
                        borderWidth: 2,
                        pointRadius: 7,
                        pointBorderColor: '#FFF',
                        data: this.chartdata
                    }
                ]
            }, this.options)
        }
    }
</script>

<style scoped>

</style>