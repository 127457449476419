<template>
    <b-row class="bg-white pt-5 pb-5">
        <b-col md="6" offset-md="3">
            <UploadImage title="Header image" :endpoint="`uploadheaderimage/`+fid" :image="falcon.title_image || `/media/5f0dd8614be58_header_1.jpeg`" :restrictions="pixelRestrictionsHeader" :stencilRatio="{ aspectRatio: 3/4 }"></UploadImage>
            <UploadImage title="Avatar image" :endpoint="`uploadavatarimage/`+fid" :image="falcon.avatar_image || `/media/5f0de2e68abdd_avatar_1.jpeg`" :restrictions="pixelRestrictionsAvatar" :stencilRatio="{ aspectRatio: 4/3 }"></UploadImage>
            <div class="card">
                <div class="card-header">Falcon</div>
                <div class="card-body">
                    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                        <b-form-group
                                v-if="this.$store.getters.isAdmin"
                                id="input-group-1"
                                label="UID:"
                                label-for="input-1"
                        >
                            <b-form-input
                                    v-if="this.$store.getters.isAdmin"
                                    id="input-1"
                                    v-model="falcon.uid"
                                    v-mask="macMask"
                                    masked="true"
                                    required
                                    placeholder="UID"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                                v-if="this.$store.getters.isAdmin"
                                id="input-group-2"
                                label="HASH:"
                                label-for="input-2"
                        >
                            <b-form-input
                                    v-if="this.$store.getters.isAdmin"
                                    id="input-2"
                                    v-model="falcon.hash"
                                    required
                                    placeholder="HASH"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                                id="input-group-3"
                                label="Name:"
                                label-for="input-3"
                        >
                            <b-form-input
                                    id="input-3"
                                    v-model="falcon.name"
                                    placeholder="Name"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                                v-if="this.$store.getters.isAdmin"
                                id="input-group-4"
                                label="Ring:"
                                label-for="input-4"
                        >
                            <b-form-input
                                    v-if="this.$store.getters.isAdmin"
                                    id="input-4"
                                    v-model="falcon.ring"
                                    placeholder="Offical ring number"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            v-if="this.$store.getters.isAdmin"
                                id="input-group-5"
                                label="Name of the breeder:"
                                label-for="input-5"
                        >
                            <b-form-input
                                v-if="this.$store.getters.isAdmin"
                                    id="input-5"
                                    v-model="falcon.zuechter_name"
                                    placeholder="Name of the breeder"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            v-if="this.$store.getters.isAdmin"
                                id="input-group-6"
                                label="Website of the breeder:"
                                label-for="input-6"
                        >
                            <b-form-input
                                v-if="this.$store.getters.isAdmin"
                                    id="input-6"
                                    v-model="falcon.zuechter_url"
                                    placeholder="https://www.example.com or http://..."
                            ></b-form-input>
                        </b-form-group>

                        <div>
                            <b-button-group>
                                <b-button type="submit" variant="success">Save</b-button>
                                <b-button type="reset" variant="secondary">Reset</b-button>
                                <b-button type="button" v-if="this.$store.getters.isAdmin" v-on:click="$router.go(-1)" variant="light">Cancel</b-button>
                                <b-button type="button" v-if="this.$store.getters.isAdmin" v-on:click="onDelete" variant="danger">Delete</b-button>
                            </b-button-group>
                        </div>
                    </b-form>
                </div>
                <b-alert show v-if="err" class="mt-3" variant="danger" >Error: {{ err }}</b-alert>
                <b-card v-if="msg" class="mt-3" :header="msg">
                    <b-button type="button" v-on:click="$router.go(-1)" variant="light">Back</b-button>
                    <!-- <pre class="m-0">{{ msg }}</pre> -->
                </b-card>
            </div>
            <CreateWeight v-if="falcon.uid" :falcon-uid="falcon.uid" class="mt-5"></CreateWeight>
        </b-col>
    </b-row>
</template>

<script>
    import UploadImage from "./UploadImage";
    import CreateWeight from "./CreateWeight";

    export default {
        name: "Edit",
        components: {
          UploadImage, CreateWeight
        },
        props: {
          falconId: {
              type: Number,
              default: null
          }
        },
        data() {
            return {
                falcon: {
                },
                show: true,
                msg: '',
                err: '',
                macMask: {
                    mask: 'FF:FF:FF:FF:FF:FF:FF',
                    tokens: {
                        'F': {
                            pattern: /[0-9a-fA-F]/,
                            transform: function(v) {
                                return v.toLocaleUpperCase();
                            }
                        },
                        '#': {pattern: /\d/},
                        'X': {pattern: /[0-9a-zA-Z]/},
                        'S': {pattern: /[a-zA-Z]/},
                        'A': {pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase()},
                        'a': {pattern: /[a-zA-Z]/, transform: v => v.toLocaleLowerCase()},
                        '!': {escape: true}
                    }
                }
            }
        },
        computed: {
            fid () {
                return this.falconId || this.$route.params.id
            }
        },
        mounted() {
            this.$http.get('/api/v1/falcons/'+this.fid).then(response => this.falcon = response.data).catch(error => console.log(error))
        },
        methods: {
            pixelRestrictionsAvatar() {
                return { minWidth: 300, minHeight: 225 }
            },
            pixelRestrictionsHeader() {
                return { minWidth: 1920, minHeight: 1080 }
            },
            onSubmit(evt) {
                evt.preventDefault()
                this.$http.put('/api/v1/falcons/'+this.fid, this.falcon).then(
                    response => this.msg = 'Updated Data '+response.data.id
                ).catch(
                    error => {
                        this.$nextTick(() => {
                            this.err = error.response.data.error.message
                            this.msg = ''
                        })
                    })
            },
            onReset(evt) {
                evt.preventDefault()
                // Reset our form values
                this.$http.get('/api/v1/falcons/'+this.fid).then(response => this.falcon = response.data, this.msg = '').catch(error => console.log(error))
                // Trick to reset/clear native browser form validation state
                this.show = false
                this.$nextTick(() => {
                    this.show = true
                })
            },
            onDelete(evt) {
                evt.preventDefault()
                this.$confirm(
                    {
                        message: `Are you sure to delete the falcon?`,
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        callback: confirm => {
                            if (confirm) {
                                this.$http.delete('/api/v1/falcons/'+this.fid).then(response => this.msg = response.data, this.$router.push('/admin/falcons')).catch(error => console.log(error))
                            }
                        }
                    }
                )
            },
            onImageUpload() {
                this.$refs.holder.$el.click()
            },
            onImageDelete(evt) {
                evt.preventDefault()
                this.falcon.title_image = ''
            }
        }

    }
</script>

<style>

</style>